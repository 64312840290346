import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Form,
  toast,
} from "@/components/ui";
import { PricingItemContent } from "@/lib/server/pricing/types";
import { PossiblePlansValues } from "@/lib/shared/pricing/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  LocalizedPlanDetails,
  planEdit,
  PlanEditFormType,
} from "../types/types";
import {
  fetchPricingItems,
  usePricingItemsStore,
} from "../../stores/pricingItemsStore";
import { usePricingStore } from "../../stores/pricingStore";
import axios from "axios";
import i18nConfig from "@/i18nConfig";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useTranslation } from "react-i18next";
import { StripePlanBenefit } from "@/prisma/schema/mysql";

export default function PlanEdit({
  isOpen,
  setIsOpen,
  pricingItem,
  plan,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pricingItem: PricingItemContent;
  plan: PossiblePlansValues;
}) {
  const pricingStore = usePricingStore();
  const pricingItemsStore = usePricingItemsStore();
  const { i18n } = useTranslation();
  const [selectedLocale, setSelectedLocale] = useState<string>(
    i18nConfig.defaultLocale,
  );
  const [localizedDetails, setLocalizedDetails] = useState<Record<
    string,
    LocalizedPlanDetails
  > | null>(null);

  const form = useForm<PlanEditFormType>({
    resolver: zodResolver(planEdit),
    defaultValues: {
      tagline: "",
      features: [],
      name: "",
      locale: i18nConfig.defaultLocale,
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    control,
    formState: { errors },
  } = form;

  const resetForm = () => {
    if (!pricingItem) return;
    reset({
      tagline: pricingItem.tagline,
      features: pricingItem.features,
      name: pricingItem.plan,
    });
  };

  useEffect(() => {
    if (isOpen) {
      fetchLocaleDetails(i18nConfig.defaultLocale);
    }
  }, [isOpen, plan]);

  useEffect(() => {
    if (!pricingItem) return;
    resetForm();
  }, [pricingItem, reset]);

  const features = watch("features");

  const fetchLocaleDetails = async (locale: string) => {
    try {
      const response = await axios.get("/api/pricing/plan-locales", {
        params: {
          remoteMetadataName: plan.remoteMetadataName,
          billing: plan.billing,
        },
      });

      // Store all localized details in a record for easy lookup
      const detailsMap = response.data.reduce(
        (
          acc: Record<string, LocalizedPlanDetails>,
          detail: LocalizedPlanDetails,
        ) => ({
          ...acc,
          [detail.locale]: detail,
        }),
        {} as Record<string, LocalizedPlanDetails>,
      );

      setLocalizedDetails(detailsMap);

      // Reset form with fetched or empty details
      const details = detailsMap[locale];
      reset({
        name: details?.name || "",
        tagline: details?.description || "",
        features: details?.benefits?.length
          ? details.benefits.map((b: StripePlanBenefit) => ({
              text: b.text,
              footnote: b.footnote,
            }))
          : [{ text: "", footnote: "" }],
        locale,
      });
    } catch (e) {
      console.error({ e });
      toast({
        variant: "destructive",
        description: "Could not fetch locale details",
      });
    }
  };

  const handleLocaleChange = (locale: string) => {
    setSelectedLocale(locale);
    // If we already have the details, use them; otherwise fetch new ones
    if (localizedDetails && locale in localizedDetails) {
      const details = localizedDetails[locale];
      reset({
        name: details.name,
        tagline: details.description,
        features: details.benefits.map((b: StripePlanBenefit) => ({
          text: b.text,
          footnote: b.footnote,
        })),
        locale,
      });
    } else {
      reset({
        name: "",
        tagline: "",
        features: [{ text: "", footnote: "" }],
        locale,
      });
    }
  };

  const onSubmit = async (values: PlanEditFormType) => {
    try {
      await axios.put(`/api/pricing/plans`, {
        plan: values,
        billing: plan.billing,
        remoteMetadataName: plan.remoteMetadataName,
        locale: selectedLocale,
      });
      toast({
        duration: 4000,
        description: "Plan updated successfully",
      });
      const { pricingItems } = await fetchPricingItems(
        pricingStore,
        i18n.language,
      );
      pricingItemsStore.setPricingItems(pricingItems);
      setIsOpen(false);
    } catch (e) {
      console.error(e);
      toast({
        duration: 4000,
        variant: "destructive",
        description: "Could not update plan",
      });
    }
    setIsOpen(false);
  };

  const addFeature = () => {
    setValue("features", [...features, { footnote: "", text: "" }]);
  };

  const deleteFeature = (index: number) => {
    setValue(
      "features",
      features.filter((_, i) => i !== index),
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="flex text-2xl justify-center items-center flex-col gap-y-4 pb-2">
            "Edit" product details
          </DialogTitle>
          <Select value={selectedLocale} onValueChange={handleLocaleChange}>
            <SelectTrigger>
              <SelectValue placeholder="Select locale" />
            </SelectTrigger>
            <SelectContent>
              {i18nConfig.locales.map((locale) => (
                <SelectItem key={locale} value={locale}>
                  {locale}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Form {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="shadcn"
                          {...field}
                          className="p-1"
                        />
                      </FormControl>
                      <FormDescription>The plan's display name</FormDescription>
                      <FormMessage>{errors.name?.message}</FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="tagline"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tagline</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="shadcn"
                          {...field}
                          className="p-1"
                        />
                      </FormControl>
                      <FormDescription>Plan's description</FormDescription>
                      <FormMessage>{errors.tagline?.message}</FormMessage>
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="features"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Features</FormLabel>
                      <FormControl>
                        <div className="max-h-64 overflow-y-auto">
                          {field.value.map((feature, index) => (
                            <div
                              key={index}
                              className="flex flex-col gap-y-2 mb-4 p-4 border rounded-lg"
                            >
                              <div className="flex items-center gap-x-2 mb-2">
                                <FormField
                                  control={control}
                                  name={`features.${index}.text`}
                                  render={({ field }) => (
                                    <FormItem className="flex-1">
                                      <FormLabel>
                                        Feature {index + 1} text
                                      </FormLabel>
                                      <FormControl>
                                        <Input {...field} className="p-1" />
                                      </FormControl>
                                      <FormMessage>
                                        {
                                          errors.features?.[index]?.text
                                            ?.message
                                        }
                                      </FormMessage>
                                    </FormItem>
                                  )}
                                />
                                <button
                                  type="button"
                                  onClick={() => deleteFeature(index)}
                                  className="text-red-500 bg-red-100 hover:bg-red-200 px-2 py-1 rounded"
                                >
                                  Delete
                                </button>
                              </div>
                              <FormField
                                control={control}
                                name={`features.${index}.footnote`}
                                render={({ field }) => (
                                  <FormItem className="flex-1">
                                    <FormLabel>Footnote</FormLabel>
                                    <FormControl>
                                      <Input {...field} className="p-1" />
                                    </FormControl>
                                    <FormMessage>
                                      {
                                        errors.features?.[index]?.footnote
                                          ?.message
                                      }
                                    </FormMessage>
                                  </FormItem>
                                )}
                              />
                            </div>
                          ))}
                        </div>
                      </FormControl>
                      <FormMessage>{errors.features?.message}</FormMessage>
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex ml-auto w-max gap-2 ">
                <button
                  type="button"
                  onClick={() => {
                    resetForm();
                  }}
                  className="mt-4 text-white-500 bg-red-500 hover:bg-red-600 px-4 py-2 rounded"
                >
                  Reset Form
                </button>
                <button
                  type="button"
                  onClick={addFeature}
                  className="mt-4 text-blue-500 bg-blue-100 hover:bg-blue-200 px-4 py-2 rounded"
                >
                  Add Feature
                </button>
                <button
                  type="submit"
                  className="flex bg-black text-white mt-4 px-4 py-2 rounded ml-auto"
                >
                  Update Plan
                </button>
              </div>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
