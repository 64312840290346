"use client";

import { ArrowRight } from "lucide-react";
import { Button, toast } from "@/components/ui";
import { trpc } from "@/app/_trpc/client";
import { PRODUCTS } from "@/config/plans";
import clsx from "clsx";
import {
  PossiblePlanKeysNoFree,
  PossiblePlansValues,
} from "@/lib/shared/pricing/utils";
import { PlanCardType } from "../types/types";
import usePricing from "@/components/Hooks/pricing/usePricingFetch";
import { PricingStoreType } from "../../stores/pricingStore";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";

const UpgradeButton = ({
  isSubscribedToThisPlan = false,
  freeTrialAvailable,
  planToUpgradeTo,
  buttonStyle,
  isDisabled = false,
  activeBilling,
  pricingStore,
  isComingSoon = false,
  user,
}: {
  activeBilling: "monthly" | "yearly" | false;
  isSubscribedToThisPlan: boolean;
  freeTrialAvailable: boolean;
  buttonStyle?: string;
  isDisabled?: boolean;
  planToUpgradeTo: PossiblePlansValues | undefined;
  pricingStore: PricingStoreType;
  isComingSoon?: boolean;
  user?: boolean;
}) => {
  const router = useRouter();
  const { t } = useTranslation("pricing");
  const { mutate: createStripeSession } = trpc.createStripeSession.useMutation({
    onSuccess: ({ url }) => {
      window.location.href = url ?? "/dashboard/billing";
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        title: "Error",
        description: err.message,
        duration: 3000,
      });
    },
  });

  const handleCheckout = async () => {
    if (!user) {
      router.push("/login");
    } else {
      createStripeSession({
        planType: planToUpgradeTo!.remoteMetadataName,
        billing: planToUpgradeTo!.billing,
      });
    }
  };

  let textToShowIfProPlanIsNotActivated;

  if (freeTrialAvailable)
    textToShowIfProPlanIsNotActivated = t("priceCard.startFree");
  else if (isDisabled) {
    if (activeBilling === "yearly")
      textToShowIfProPlanIsNotActivated = t("priceCard.changeNextYear");
  } else textToShowIfProPlanIsNotActivated = t("priceCard.buyNow");

  if (!pricingStore.plans || !planToUpgradeTo) return null;

  return (
    <Button
      disabled={isDisabled || isComingSoon}
      onClick={handleCheckout}
      className={clsx(
        "w-full transition-colors",
        buttonStyle ? buttonStyle : "bg-blue-600 hover:bg-blue-800",
      )}
    >
      {isComingSoon
        ? t("priceCard.comingSoon")
        : isSubscribedToThisPlan
          ? t("priceCard.manage")
          : textToShowIfProPlanIsNotActivated}{" "}
      <ArrowRight className="h-5 w-5 ml-1.5" />
    </Button>
  );
};

export const CreditsButton = ({
  productType,
}: {
  productType: keyof typeof PRODUCTS;
}) => {
  const { mutate: createStripeSession } = trpc.createStripeSession.useMutation({
    onSuccess: ({ url }) => {
      window.location.href = url ?? "/dashboard/billing";
    },
  });

  return (
    <Button
      onClick={() => createStripeSession({ productType: productType })}
      className="w-full"
    >
      Buy now <ArrowRight className="h-5 w-5 ml-1.5" />
    </Button>
  );
};

export default UpgradeButton;
