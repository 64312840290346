"use client";
import { PlanCardType } from "../types/types";
import {
  getLogicForInteractivity,
  getPlanCss,
  getStyles,
  renderCancellationNotice,
} from "./utils";
import PriceCard from "./PriceCard";
import {
  findDefaultPriceOfPlan,
  findOriginalPriceOfPlan,
  PossiblePlanKeys,
  PossiblePlansNoFree,
} from "@/lib/shared/pricing/utils";
import { usePricingStore } from "../../stores/pricingStore";
import PlanEdit from "./PlanEdit";
import { useState } from "react";
import { usePricingItemsStore } from "../../stores/pricingItemsStore";
import { useSession } from "next-auth/react";
import { useTranslation } from "react-i18next";

const PlanBase = ({
  activeSubscriptionPlan,
  planType,
  user,
  isThirdItem = false,
}: PlanCardType & {
  planType: Exclude<PossiblePlanKeys, "freePlan">;
  isThirdItem?: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pricingStore = usePricingStore();
  const { pricingItems } = usePricingItemsStore();
  const { t } = useTranslation("pricing");
  const session = useSession();
  const isAdmin =
    session.data?.user?.role === "admin" && !!session.data?.user?.adminMode;

  if (!pricingStore.plans) return null;

  const allPlans = pricingStore.plans;
  const {
    isSubscribedToThisPlan,
    isUpgradeOrDowngrade,
    isDisabledUpgradeButton,
    activeBilling,
    freeTrialAvailable,
  } = getLogicForInteractivity({
    planSubscribingToType: planType,
    activeSubPlan: activeSubscriptionPlan,
    allPlans,
  });

  const sbPlan = {
    [planType]: allPlans[planType],
  } as PossiblePlansNoFree;

  const defaultPrice = findDefaultPriceOfPlan(sbPlan);
  const originalPrice = findOriginalPriceOfPlan(sbPlan);
  const discount = 100 - (defaultPrice!.amount / originalPrice!.amount) * 100;

  const pricingItemSought = pricingItems ? pricingItems[planType] : undefined;
  if (!pricingItemSought) return null;

  const isComingSoon = pricingItemSought.comingSoon;
  const planCss = getPlanCss(planType);
  const { renderFooter } = getStyles({
    isSubscribedToThisPlan,
    isUpgradeOrDowngrade,
    isDisabledUpgradeButton,
    activeBilling,
    freeTrialAvailable,
    planType,
    pricingStore,
    isComingSoon,
    user,
  })!;

  return (
    <>
      {isAdmin && (
        <PlanEdit
          plan={allPlans[planType]}
          pricingItem={pricingItemSought}
          isOpen={isModalOpen}
          setIsOpen={() => setIsModalOpen(!isModalOpen)}
        />
      )}
      <PriceCard
        planCss={planCss}
        isAdmin={isAdmin}
        openEditModal={() => setIsModalOpen(!isModalOpen)}
        wrapperForPlanBannerText={
          isComingSoon
            ? t("priceCard.comingSoon")
            : isSubscribedToThisPlan
              ? t("priceCard.current")
              : discount > 0
                ? t("priceCard.save", { discount: Math.floor(discount) })
                : t("priceCard.upgrade")
        }
        planTitle={pricingItemSought!.plan}
        tagline={pricingItemSought!.tagline}
        features={pricingItemSought!.features}
        footer={renderFooter()}
        isComingSoon={isComingSoon}
        sbPlan={sbPlan}
        activeSbPlan={activeSubscriptionPlan}
        isThirdItem={isThirdItem}
      />{" "}
    </>
  );
};

export default PlanBase;
