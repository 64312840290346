"use client";
import { AllPricingItems } from "@/lib/server/pricing/types";
import { create } from "zustand";
import { PricingStoreType } from "./pricingStore";

export interface PricingItemsStoreType {
  pricingItems: AllPricingItems | undefined;
  setPricingItems: (pricingItems: AllPricingItems | undefined) => void;
}

export const usePricingItemsStore = create<PricingItemsStoreType>((set) => ({
  pricingItems: undefined,
  setPricingItems: (pricingItems) => set({ pricingItems }),
}));

export const fetchPricingItems = async (
  pricingStore: PricingStoreType,
  locale: string,
) => {
  try {
    if (!pricingStore.plans)
      throw new Error("No plans found for fetching pricing items");

    const response = await fetch(
      `/api/pricing/pricing_items?locale=${locale}`,
      {
        method: "GET",
        cache: "no-cache",
        next: {
          revalidate: 30,
        },
      },
    );
    const pricingItemsSought = await response.json();
    return { pricingItems: pricingItemsSought };
  } catch (err) {
    console.error("Could not fetch pricing items");
    return { pricingItems: null };
  }
};
