import { getUserSubscriptionPlan } from "@/lib/server/pricing/stripe";
// import { PricingStoreType } from "../../stores/pricingStore";
import { AllPricingItems } from "@/lib/server/pricing/types";
import { z } from "zod";
import { BillingFrequency, StripePlanBenefit } from "@/prisma/schema/mysql";

export type PlanCardType = {
  activeSubscriptionPlan: Awaited<ReturnType<typeof getUserSubscriptionPlan>>;
  billing: "monthly" | "yearly";
  isViewOnly?: boolean;
  user?: boolean;
  // pricingStore: PricingStoreType;
  pricingItems?: AllPricingItems;
};

export type PlanEditFormType = {
  tagline: string;
  features: { text: string; footnote: string }[];
  name: string;
  locale: string;
};

export const planEdit = z.object({
  tagline: z.string().min(1, "Tagline is required"),
  features: z
    .array(
      z.object({
        text: z.string().min(1, "Feature text is required"),
        footnote: z.string().min(1, "Feature footnote is required"),
      }),
    )
    .min(1, "At least one feature is required")
    .max(5, "Maximum of 5 features allowed"),
  name: z.string().min(1, "Name is required"),
  locale: z.string().min(2, "Locale is required").max(5),
});

export type PlanEditInferred = z.infer<typeof planEdit>;

export type TrackingData = {
  transaction_id: string;
  value: number;
  currency: string;
  items: Array<{
    item_id: string;
    item_name: string;
    price: number;
    quantity: number;
  }>;
};

export type LocalizedPlanDetails = {
  id: string;
  productId: string;
  productBilling: BillingFrequency;
  locale: string;
  name: string;
  description: string;
  benefits: StripePlanBenefit[];
};
